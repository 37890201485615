import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import colors from "../utils/colors"
import {
  Container,
  PageTitle,
  PostText,
  mobileWidth,
  desktopWidth
} from "../utils/common-styles"
import dev from "../images/svg/dev.svg"
import VictorBjorklund from '../images/victor_bjorklund.png'


const NavTitle = styled.h4`
  width: 180px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.WHITE};
  margin-bottom: 20px;
`

const BioCard = styled.div`
padding-top: 40px;
  border-top: 1px solid ${colors.BLUE};
  font-size: 18px;
  line-height: 29px;
  
`

const BioImage = styled.img`
    width: 100%;
    float: left;
    border-radius: 100%;
    border: 1px solid black;
`;




const AuthorBio = props => (
  <>
      <BioCard>
  <h4>Om Rekryterautvecklare.se</h4>


  <p>Jag skapade sidan för att jag såg ett behov av information om var man kan hitta duktiga utvecklare. Jag har märkt att det inte finns mycket information som på ett lätt sätt förklarar hur och var man hitta duktiga utvecklare.</p>
<strong>Victor Björklund</strong>



  

  </BioCard>

  </>
)

export default AuthorBio
